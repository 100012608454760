<template>
  <component :is="projectData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="projectData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching project data
      </h4>
      <div class="alert-body">
        No project found with this project id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-projects-list'}"
        >
          Project List
        </b-link>
        for other projects.
      </div>
    </b-alert>

    <b-row class="m-0">
      <!-- Left Text-->
      <b-col
        lg="4"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            ID Cliente: {{ projectData.client_id }}

            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="surname"
              >
                <b-form-input
                  id="name"
                  v-model="projectData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- reference_code -->
            <validation-provider
              #default="validationContext"
              name="reference_code"
              rules="required"
            >
              <b-form-group
                label="reference_code"
                label-for="reference_code"
              >
                <b-form-input
                  id="reference_code"
                  v-model="projectData.reference_code"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- expected_start_date -->
            <validation-provider
              #default="validationContext"
              name="expected_start_date"
            >
              <b-form-group
                label="expected_start_date"
                label-for="expected_start_date"
              >
                <b-form-input
                  id="expected_start_date"
                  v-model="projectData.expected_start_date"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- real_start_date -->
            <validation-provider
              #default="validationContext"
              name="real_start_date"
            >
              <b-form-group
                label="real_start_date"
                label-for="real_start_date"
              >
                <b-form-input
                  id="real_start_date"
                  v-model="projectData.real_start_date"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- real_finish_date -->
            <validation-provider
              #default="validationContext"
              name="real_finish_date"
            >
              <b-form-group
                label="real_finish_date"
                label-for="real_finish_date"
              >
                <b-form-input
                  id="real_finish_date"
                  v-model="projectData.real_finish_date"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- expected_finish_date -->
            <validation-provider
              #default="validationContext"
              name="expected_finish_date"
            >
              <b-form-group
                label="expected_finish_date"
                label-for="expected_finish_date"
              >
                <b-form-datepicker
                  id="expected_finish_date"
                  v-model="projectData.expected_finish_date"
                  locale="es-ES"
                  start-weekday="1"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- address1 -->
            <validation-provider
              #default="validationContext"
              name="address1"
            >
              <b-form-group
                label="address1"
                label-for="address1"
              >
                <b-form-input
                  id="address1"
                  v-model="projectData.address1"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- city -->
            <validation-provider
              #default="validationContext"
              name="city"
            >
              <b-form-group
                label="city"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  v-model="projectData.city"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- installation_type -->
            <validation-provider
              #default="validationContext"
              name="installation_type"
            >
              <b-form-group
                label="installation_type"
                label-for="installation_type"
              >
                <b-form-input
                  id="installation_type"
                  v-model="projectData.installation_type"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- power -->
            <validation-provider
              #default="validationContext"
              name="power"
            >
              <b-form-group
                label="power"
                label-for="power"
              >
                <b-form-input
                  id="power"
                  v-model="projectData.power"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- responsible_id -->
            <validation-provider
              #default="validationContext"
              name="responsible_id"
              rules="required"
            >
              <b-form-group
                label="Responsible"
                label-for="responsible_id"
              >
                <v-select
                  id="responsible_id"
                  v-model="projectData.responsible_id"
                  autofocus
                  :state="getValidationState(validationContext)"
                  :options="users"
                  :reduce="users => users.id"
                  label="name"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Save changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
              >
                Cancel
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </b-col>
      <b-col
        lg="8"
      >
        <b-tabs
          v-if="projectData"
          pills
        >

          <!-- Tab: Account -->
          <b-tab active>
            <template #title>
              <feather-icon
                icon="ProjectIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Account</span>
            </template>
            <project-edit-tab-account
              :project-data="projectData"
              class="mt-2 pt-75"
            />
          </b-tab>

          <!-- Tab: Information -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="InfoIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Information</span>
            </template>
            <project-edit-tab-information class="mt-2 pt-75" />
          </b-tab>

          <!-- Tab: Social -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="Share2Icon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Social</span>
            </template>
            <project-edit-tab-social class="mt-2 pt-75" />
          </b-tab>
        </b-tabs>

      </b-col>
    </b-row>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BRow,
  BCol,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BCardText,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
  BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import vSelect from "vue-select"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { required, alphaNum } from '@validations'
import useUsersList from "@/views/apps/user/users-list/useUsersList"
import formValidation from "@core/comp-functions/forms/form-validation"
import Ripple from "vue-ripple-directive"
import ProjectEditTabAccount from './ProjectEditTabAccount.vue'
import ProjectEditTabInformation from './ProjectEditTabInformation.vue'
import ProjectEditTabSocial from './ProjectEditTabSocial.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardText,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BFormDatepicker,
    BFormSelect,
    BFormCheckbox,

    ProjectEditTabAccount,
    ProjectEditTabInformation,
    ProjectEditTabSocial,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup() {
    const projectData = ref(null)

    const { users, getUsers } = useUsersList()

    onMounted(getUsers)

    store.dispatch('model/project/fetchProject', { id: router.currentRoute.params.id })
      .then(response => { projectData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          projectData.value = undefined
        }
      })

    const onSubmit = () => {
      store.dispatch('model/project/editProject', projectData.value)
          .then(response => {
            // router.push({ name: 'projects-edit', params: { id: response.data.id } })
          })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(projectData)

    return {
      users,
      projectData,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style>

</style>
